import React from "react";
import styles from "./Fyers.module.css";
import rightside from "./img/down.svg";
import trade from "./img/trade.png";
import reusable from "./img/reusable.png";
import dot from "./img/dot.png";
import about from "./img/about.png";
import pro1 from "./img/icon-fyers-one.png";
import app from "./img/icon-fyers-app-ea2.png";
import instaoptions from "./img/icon-fyers-instaoptions 1.png";
import web2 from "./img/icon-fyers-web2.png";
import alerts from "./img/icon-price-alerts.png";
import tv from "./img/tv.png";
import grp from "./img/Group1321314145.png";
import grp2 from "./img/Group 1321314144.png";
import grpn from "./img/Group 1321314127.png";
import grpd from "./img/Group1321314127d.png";
import grps from "./img/Group 1321314127s.png";
import grpr from "./img/Group 1321314127r.png";
import Groupj from "./img/Groupj.png";
import Groups from "./img/Groups.png";
import Groupc from "./img/Groupc.png";
import Groupa from "./img/Groupa.png";
import Groupapi from "./img/Groupapi.png";
import Groupalogo from "./img/Groupalogo.png";
import download2 from "./img/download2.png";
import Tejas from "./img/Tejas.png";
import Polygon1 from "./img/yash.png";
import dematacc1 from "./img/dematacc1.png";
import online2 from "./img/online2.png";
import online1 from "./img/online1.png";
import rectexplain from "./img/Rectangle60.png";
import alertss from "./img/alterss.png";
import notes from "./img/notes.png";
import disclosure from "./img/disclosure.png";
import buy from "./img/buy.png";
import image8 from "./img/image8.png";
import { Helmet } from "react-helmet";
import Footer from "../../Widgets/Footer/Footer";
import Navbar from "../../Widgets/Navbar/Navbar";

const Fyers = () => {
  const link = "https://signup.fyers.in/?utm_source=AP-Leads&utm_medium=AP0251";
  return (
    <React.Fragment>
      <div className={styles.page}>
        <Navbar />
        <Helmet>
          <title>Fyers Free Demat Account Opening Lifetime Free AMC</title>
          <meta
            name="keywords"
            content="fyers, fyers account opening, fyers demat account, fyers review, fyers demat account charges, fyers demat account opening, stock broker in lucknow, best stock broker in lucknow, 0 brokerage on delivery, free demat account"
          />
          <meta name="description" content="" />
          <meta name="robots" content="index" />
          <link href="https://www.lucknowlions.com/fyers" rel="canonical" />

        </Helmet>

        <main className={styles.mainContent}>
          <div style={{ height: "60px" }} />

          <img
            className={styles.logo}
            src="https://assets.fyers.in/images/logo.svg"
            alt="Fyers"
          />

          {/* section1 */}
          <section className={styles.hero}>
            <div className={styles.lefthero}>
              <h1>
                Trading,
                <br />
                <span>Simplified.</span>
              </h1>
              <p>
                Enjoy hassle free trade along with competitive trading charges..
              </p>
              <a href={link} target="_blank" className={styles.a}>
                <img src={trade} alt="icon" />
                <span> Open Your Demat Account</span>
              </a>
            </div>

            <div className={styles.righthero}>
              <img src={rightside} alt="hero" />
            </div>
          </section>

          <p className={styles.focus}>
            <img src={reusable} alt="icon" width="70" />
            Focus Your Energy & Reform the Self
          </p>

          <div className={styles.dotsbg} />

          {/* section3 */}
          <section2 className={styles.about}>
            <div className={styles.aboutleft}>
              <img src={about} alt="about" />
            </div>
            <div className={styles.aboutright}>
              <h3>
                <span className={styles.colortxt}>About</span> Fyers
              </h3>
              <p>
                Fyers was incorporated in 2015, Fyers Securities is a Bangalore
                based online discount stock broker. The company was founded by
                young entrepreneurs who believe in building the best trading
                platforms, being transparent and keeping the cost of trading at
                its lowest in India. Fyers is a lowest brokerage stock broker in
                India. They are among the cheapest stock brokers in whole online
                discount broker industry. Fyers follow simple price model that
                you offer trading across all the segments for the same brokerage
                fee of a maximum of Rs 20 per executed order. No matter how big
                your order, Fyers charge a maximum of Rs 20 per executed order.
                Fyers doesn't charge any brokerage in the Equity Delivery
                segment for the lifetime for all Age group.
              </p>
            </div>
          </section2>

          {/* section4 */}
          <section className={styles.products}>
            <h3 className={styles.h3}>
              Fyers <span className={styles.colortxt}>Products</span>
            </h3>

            <div className={styles.productcards}>
              <div className={styles.productcard}>
                <img src={pro1} alt="product" />
                <h4>Fyers One</h4>
                <p>
                  Our cutting-edge analytics tools make Fyers One the best
                  desktop trading platform one can ask for.
                </p>
              </div>
              <div className={styles.productcard}>
                <img src={web2} alt="product" />
                <h4>Fyers Web</h4>
                <p>
                  Trade smarter and more efficiently with advanced charting
                  tools and realtime market data
                </p>
              </div>
              <div className={styles.productcard}>
                <img src={app} alt="product" />
                <h4>Fyers App</h4>
                <p>
                  Trade Stocks, Options, Futures, ETFS, and More on Your Mobile
                  with Ease and Convenience.
                </p>
              </div>
              <div className={styles.productcard}>
                <img src={alerts} alt="product" />
                <h4>Fyers Alert</h4>
                <p>
                  Stay Update with get alert of latest price for Equity, F&O,
                  and more.
                </p>
              </div>
              <div className={styles.productcard}>
                <img src={tv} alt="product" />
                <h4>Trading view</h4>
                <p>
                  Check out the trading ideas, strategies, opinions, analytics
                  at absolutely no cost with Fyers in premium version!
                </p>
              </div>
              <div className={styles.productcard}>
                <img src={instaoptions} alt="product" />
                <h4>Insta option</h4>
                <p>
                  Tools specifically tailored for Futures and Options analysis,
                  and execution Free of cost.
                </p>
              </div>
            </div>
          </section>

          <img src={grp} alt="icon" className={styles.dot} />

          {/* section5 */}
          <section className={styles.plans}>
            <h3 className={styles.h3}>
              Fyers <span className={styles.colortxt}>Investment</span> Plans
            </h3>

            <div className={styles.planscards}>
              <div className={styles.plancards}>
                <div className={`${styles.plancard} ${styles.plancard1}`}>
                  <img src={grpn} alt="product" className={styles.planimg} />
                  <h4>Fyers IPO</h4>
                  <p>
                    Be the first to apply for IPOs and FPOs listed on the NSE
                    and BSE.
                  </p>
                </div>
                <div className={`${styles.plancard} ${styles.plancard2}`}>
                  <img src={grpd} alt="product" className={styles.planimg} />
                  <h4>Fyers Direct</h4>
                  <p>
                    Easily invest in Mutual fund/SIP directly without no
                    Commission.
                  </p>
                </div>
                <img src={dot} alt="icon" className={styles.dot} />
              </div>
              <div className={styles.plancards}>
                <img src={dot} alt="icon" className={styles.dot} />
                <div className={`${styles.plancard} ${styles.plancard3}`}>
                  <img src={grps} alt="product" className={styles.planimg} />
                  <h4>Government Securities</h4>
                  <p>
                    Get easily invest in Govt Bonds, Treasury bills and SDL
                    Bonds without any commission.
                  </p>
                </div>
                <div className={`${styles.plancard} ${styles.plancard4}`}>
                  <img src={grpr} alt="product" className={styles.planimg} />
                  <h4>Fyers OFS</h4>
                  <p>
                    Apply for an Offer for Sale (OFS) of companies listed on the
                    BSE/ NSE hassle free.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <img src={grp} alt="icon" className={styles.dot} />

          {/* section6 */}
          <section className={styles.ideas}>
            <h3 className={styles.h3}>
              Fyers <span className={styles.colortxt}>Learnings Ideas</span>
            </h3>

            <div className={styles.ideascards}>
              <div className={`${styles.ideacard} ${styles.ideacard1}`}>
                <img src={Groupj} alt="icon" />
                <h4>Fyers Journals</h4>
                <p>
                  Maintain your trading records, thoughts, and emotions with
                  ease in personal dairy.
                </p>
              </div>
              <div className={`${styles.ideacard} ${styles.ideacard2}`}>
                <img src={Groupc} alt="icon" />
                <h4>Fyers Community</h4>
                <p>
                  Become part of our Fyers community inside trader and investors
                  network.
                </p>
              </div>
              <div className={`${styles.ideacard} ${styles.ideacard3}`}>
                <img src={Groups} alt="icon" />
                <h4>Fyers Schools of Stocks</h4>
                <p>
                  Learn about stock market from basic to expert levels, these
                  courses are designed by market experts.
                </p>
              </div>
            </div>
          </section>

          {/* section7 */}
          <section className={styles.ideas}>
            <h3 className={styles.h3}>
              Fyers <span className={styles.colortxt}>Developers Tools</span>
            </h3>

            <div className={styles.ideascards}>
              <div className={`${styles.ideacard} ${styles.ideacard1}`}>
                <img src={Groupa} alt="icon" />
                <h4>Fyers API bridge</h4>
                <p>Customize and test your trading strategies seamlessly.</p>
              </div>
              <div
                className={`${styles.ideacard} ${styles.ideacard2}`}
                style={{ backgroundColor: "#FFAEB6" }}
              >
                <img src={Groupapi} alt="icon" />
                <h4>Fyers API</h4>
                <p>
                  Get historical data, real time data and use the power of algo
                  trading for absolutely free.
                </p>
              </div>
              <div className={`${styles.ideacard} ${styles.ideacard3}`}>
                <img src={Groupalogo} alt="icon" />
                <h4>Algo Fox</h4>
                <p>
                  Utilize custom algorithms with full of potential to execute
                  trades and achieve targeted outcomes.
                </p>
              </div>
            </div>
          </section>

          {/* section8 */}
          <section className={styles.boxs}>
            <div className={styles.boxblue}>
              <div className={styles.boxblueleft}>
                <h4>Fyers My Account</h4>
                <p>
                  Go insight and manage seamless Backoffice profiles, reports
                  and P&L etc.
                </p>
                <a href={link} target="_blank" className={styles.aa}>
                  Open Your Demat Account Now
                </a>
              </div>
              <div className={styles.boxblueright}>
                <img src={download2} alt="icon" width={100} />
              </div>
            </div>
          </section>

          {/* section9 */}
          <section className={styles.founders}>
            <h4>Founders</h4>

            <div className={styles.founder}>
              <div className={styles.circlefounder}>
                <h5>Tejas Khoday</h5>
                <h6>Co-founder & CEO </h6>
                <p>
                  In 2015, FYERS was formed as a crusade to change the way stock
                  investments are made in the country and as a
                  technology-focused brokerage firm, the goal is to transform
                  the trading/investment landscape for everyone.
                  <br />
                  <br />
                  At the age of 18 he started trading and after experiencing the
                  complacency of traditional brokerages in India, he embarked
                  upon a journey to solve the problems faced by traders and
                  investors.
                </p>
              </div>
              <img
                src="/fyers_files/founder1.png"
                alt="icon"
                className={styles.founderImg}
              />
            </div>
            <div className={styles.founder}>
              <img
                src="/fyers_files/founder2.png"
                alt="icon"
                className={styles.founderImg}
              />
              <div className={styles.circlefounder}>
                <h5>Yashas Khoday</h5>
                <h6>Co-founder & CEO </h6>
                <p>
                  As a keen explorer & problem solver, Yashas has his interest
                  spread across technology & financial markets. His ability to
                  overcome difficult tech challenges and building highly
                  scalable & robust platforms has played a pivotal role in our
                  success. In 2021, he was featured in BW Disrupt "30 Under 30"
                  for his achievements as a successful bootstrapped entrepreneur
                  & the youngest stockbroker in India, With his formidable team,
                  Yashas is focused on delivering the best experience to new-age
                  traders/investors
                </p>
              </div>
            </div>
          </section>

          <div style={{ height: "100px" }} />
          {/* section10 */}
          <section className={styles.openacc}>
            <div className={styles.openaccs}>
              <h3>
                Open Your
                <br />
                Demat Account
                <br />
                in Under
                <br />5 Minutes
              </h3>
              <img src={dematacc1} alt="icon" />
            </div>
            <div className={styles.openaccbox}>
              <div>
                <p>LIMITED PERIOD OFFER</p>
                <h5>Get Free AMC for Life</h5>
              </div>
              <a href="" className={styles.aa}>
                Sign Up NOW
              </a>
            </div>
          </section>

          {/* section11 */}
          <section className={styles.onoff}>
            <div className={styles.onoffbox}>
              <h3>Documents are required to Open Demat Account with Fyers</h3>
              <div className={styles.whiteline}></div>

              <h6>
                <img src={online2} alt="icon" width={40} /> Online
              </h6>
              <ul>
                <li>A Signature required as per PAN Card on white paper.</li>
                <li>
                  Proof of identification (POI) - PAN CARD, or Voter ID, or
                  Passport, any ID card issued by the central or state
                  government with applicant's photo.
                </li>
                <li>
                  Proof of Address (POA): ADHAR Card, or Ration card, or
                  Passport, or Voter ID, verified copies of electricity bills.
                </li>
                <li>
                  Bank Proof- Cancel cheque, or Bank Passbook or 6-month Bank
                  statement
                </li>
                <li>
                  Income Proof - 6-month Bank statement, or ITR with income
                  Computation, or Salary Slip, or Net worth certificate or Copy
                  of Form 16 or demat holding you may attach for derivatives
                  segments.
                </li>
              </ul>
              <div className={styles.whiteline}></div>
              <h6>
                <img src={online1} alt="icon" width={40} /> Offline
              </h6>
              <ul>
                <li>Self-attested photocopy of the PAN.</li>
                <li>Self-attested photocopy of the Adhaar card.</li>
                <li>2 Passport size photo (Color).</li>
                <li>Original personalizes cancel cheque.</li>
                <li>
                  Income Proof - Self attested photocopy required with your
                  signature of 6 months bank statements, or ITR with Income
                  Computation, or Salary Slip.
                </li>
              </ul>

              <div className={styles.notes}>
                <h5>Note :</h5>
                <ul>
                  <li>
                    A-These documents are necessary for the KYC (Know Your
                    Customer) process and to verify your identity and address.
                  </li>
                  <li>
                    B- It is important to link your Aadhar number with demat
                    account because it is mandatory by government of India.
                  </li>
                  <li>
                    C- PAN and Adhar Linking should be done it is also mandatory
                    by government of India.
                  </li>
                  <li>
                    D- If you want open joint demat and trading account then all
                    members are required to submit self-attested photocopy of
                    the documents and passport size photo.
                  </li>
                </ul>
              </div>
            </div>
          </section>

          {/* section12 */}
          <section className={styles.tables}>
            <h3>
              Charges{" "}
              <span className={styles.des1}>
                (Few charges are revised from 01.Oct.2024)
              </span>{" "}
            </h3>
            <table>
              <tr>
                <th>Segment</th>
                <th>Brokerage</th>
              </tr>
              <tr>
                <td>Trading & Demat Account Opening</td>
                <td>Free</td>
              </tr>
              <tr>
                <td>Annual Maintenance Charges (AMC)</td>
                <td>Lifetime Free in Limited period offer,(400/- year)</td>
              </tr>
              <tr>
                <td>DP Charges</td>
                <td>
                  <b> Male</b>: 3.5 per script
                  <br />
                  <br />
                  <b>Female</b>: 3.25 per script
                </td>
              </tr>
            </table>

            <img src={grp2} alt="icon" className={styles.dot} />

            <h3>Brokerages</h3>
            <table>
              <tr>
                <th>Segment</th>
                <th>Brokerage</th>
              </tr>
              <tr>
                <td>Intraday</td>
                <td>0.01% or Rs 20 per order which is lower.</td>
              </tr>
              <tr>
                <td>Delivery</td>
                <td>
                  Revised to 0.3% or ₹20 per executed order, whichever is lower.{" "}
                </td>
              </tr>
              <tr>
                <td>Future</td>
                <td>0.01% or Rs 20 per order which is lower.</td>
              </tr>
              <tr>
                <td>Option</td>
                <td>Flat Rs 20 per order</td>
              </tr>
              <tr>
                <td>Currency</td>
                <td>0.01% or Rs 20 per order which is lower.</td>
              </tr>
              <tr>
                <td>MCX (Future)</td>
                <td>0.01% or Rs 20 per order which is lower.</td>
              </tr>
              <tr>
                <td>MCX (Option)</td>
                <td>Flat Rs 20 per order</td>
              </tr>
              <tr>
                <td>Mutual Fund</td>
                <td>Free</td>
              </tr>
              <tr>
                <td>Call and Trade</td>
                <td>₹50 per executed order</td>
              </tr>
              <tr>
                <td>Admin Square Off</td>
                <td>₹50 per executed order</td>
              </tr>
            </table>

            <p>
              <span>Note:</span> - Brokerage charges are applicable when your
              order executes successfully.
            </p>

            <h3>
              Statutory Charges - <span>Equity</span>
            </h3>
            <table className={styles.tablecus}>
              <tr>
                <th>Charges</th>
                <th>Delivery</th>
                <th>Intraday</th>
                <th>Futures</th>
                <th>Options</th>
              </tr>
              <tr>
                <td>STT/CTT</td>
                <td>0.1% on Buy and Sell value</td>
                <td>0.025% on Sell value</td>
                <td>0.02% on Sell valuee</td>
                <td>0.1% on Sell value (Premium)</td>
              </tr>
              <tr>
                <td>Exchange Transaction Charges</td>
                <td>
                  NSE: 0.00297%
                  <br /> <br />
                  BSE: 0.00375%
                </td>
                <td>
                  NSE: 0.00297%
                  <br /> <br />
                  BSE: 0.00375%
                </td>
                <td>NSE: 0.00173% </td>
                <td>
                  <b>NSE:</b> 0.03503% (On premium)
                  <br /> <br />
                  <b>BSE:</b><br />Sensex: 0.0325%
                  <br />
                  Bankex: 0.0325%
                  <br />
                  Sensex 50: 0.005%
                  <br />
                  Stock: 0.005%
                </td>
              </tr>
              <tr>
                <td>Clearing Charges</td>
                <td>0</td>
                <td>0</td>
                <td>0.0005%</td>
                <td>0.009%</td>
              </tr>
              <tr>
                <td>SEBI Turnover Fee</td>
                <td>₹10/Crore</td>
                <td>₹10/Crore</td>
                <td>₹10/Crore</td>
                <td>₹10/Crore</td>
              </tr>
              <tr>
                <td>GST</td>
                <td>
                  18% on (Brokerage + Exchange Transaction charges + Clearing
                  charges + SEBI Turnover Fee)
                </td>
                <td>
                  18% on (Brokerage + Exchange Transaction charges + Clearing
                  charges + SEBI Turnover Fee)
                </td>
                <td>
                  18% on (Brokerage + Exchange Transaction charges + Clearing
                  charges + SEBI Turnover Fee)
                </td>
                <td>
                  18% on (Brokerage + Exchange Transaction charges + Clearing
                  charges + SEBI Turnover Fee)
                </td>
              </tr>
              <tr>
                <td>Stamp Duty</td>
                <td>0.015%</td>
                <td>0.003%</td>
                <td>0.002%</td>
                <td>0.003%</td>
              </tr>
              <tr>
                <td>NSE IPFT</td>
                <td>₹10/Crore</td>
                <td>₹10/Crore</td>
                <td>₹10/Crore</td>
                <td>₹50/Crore (on premium)</td>
              </tr>
            </table>

            <h3>
              Statutory Charges – <span>Currency</span>
            </h3>
            <table className={styles.tablecus}>
              <tr>
                <th>Charges</th>
                <th>Futures</th>
                <th>Options</th>
              </tr>
              <tr>
                <td>STT/CTT</td>
                <td>0</td>
                <td>0</td>
              </tr>
              <tr>
                <td>Exchange Transaction Charges</td>
                <td>
                  NSE: 0.00035%
                  <br /> <br />
                  BSE: 0.00045%
                </td>
                <td>
                  NSE: 0.0311%
                  <br /> <br />
                  BSE: 0.001%
                </td>
              </tr>
              <tr>
                <td>Clearing Charges</td>
                <td>0.0005%</td>
                <td>NSE: 0.009% & BSE: 0.0005%</td>
              </tr>
              <tr>
                <td>SEBI Turnover Fee</td>
                <td>₹10/Crore</td>
                <td>₹10/Crore</td>
              </tr>
              <tr>
                <td>GST</td>
                <td>
                  18% on (Brokerage + Exchange Transaction Charges + Clearing
                  Charges + SEBI Turnover Fee)
                </td>
                <td>
                  18% on (Brokerage + Exchange Transaction Charges + Clearing
                  Charges + SEBI Turnover Fee)
                </td>
              </tr>
              <tr>
                <td>Stamp Duty</td>
                <td>0.0001% on Buy side</td>
                <td>0.0001% on Buy side</td>
              </tr>
            </table>

            <h3>
              Statutory Charges - <span>Commodity</span>
            </h3>
            <table className={styles.tablecus}>
              <tr>
                <th>Charges</th>
                <th>Futures</th>
                <th>Options</th>
              </tr>
              <tr>
                <td>STT/CTT</td>
                <td>0.01% on sell side (non-Agri)</td>
                <td>0.05% on sell side</td>
              </tr>
              <tr>
                <td>Exchange Transaction Charges</td>
                <td>MCX- Non-Agri: 0.0021%, NSE- 0</td>
                <td>MCX- Non-Agri: 0.0418%, NSE- 0</td>
              </tr>
              <tr>
                <td>Clearing Charges</td>
                <td>0.0018%</td>
                <td>0.05%</td>
              </tr>
              <tr>
                <td>SEBI Turnover Fee</td>
                <td>NON-Agree: ₹10/Crore + GST</td>
                <td>NON-Agree: ₹10/Crore + GST</td>
              </tr>
              <tr>
                <td>GST</td>
                <td>
                  18% on (Brokerage + Exchange Transaction Charges + Clearing
                  Charges + SEBI Turnover Fee)
                </td>
                <td>
                  18% on (Brokerage + Exchange Transaction Charges + Clearing
                  Charges + SEBI Turnover Fee)
                </td>
              </tr>
              <tr>
                <td>Stamp Duty</td>
                <td>0.002% on Buy side</td>
                <td>0.003% on Buy side</td>
              </tr>
            </table>

            <h3>
              <span>Other</span> Charges
            </h3>
            <table>
              <tr>
                <td>Physical Contract Note Charges</td>
                <td>₹20 per physical contract note + courier charges</td>
              </tr>
              <tr>
                <td>Physical Delivery</td>
                <td>0.2% of the contract value</td>
              </tr>
              <tr>
                <td>
                  Expires / Exercised / Assigned Futures & Options Contracts
                </td>
                <td>0</td>
              </tr>
              <tr>
                <td>Cheque Bounce Charges</td>
                <td>0</td>
              </tr>
              <tr>
                <td>Payment Gateway Charges</td>
                <td>0</td>
              </tr>
              <tr>
                <td>Pledge Request</td>
                <td>₹5</td>
              </tr>
              <tr>
                <td>Unpledge Request</td>
                <td>0</td>
              </tr>
              <tr>
                <td>Pledge Invocation</td>
                <td>₹5</td>
              </tr>
              <tr>
                <td>Margin Re-pledge</td>
                <td>₹1</td>
              </tr>
              <tr>
                <td>MTF Pledge Request</td>
                <td>₹12</td>
              </tr>
              <tr>
                <td>MTF Unpledged Requestn</td>
                <td>₹12</td>
              </tr>
              <tr>
                <td>Dematerialization Charges</td>
                <td>0</td>
              </tr>
              <tr>
                <td>Rematerialization Charges</td>
                <td>
                  Fee For every 100 securities ₹10/- Part there of; subject to
                  maximum ₹5,00,000/- Or Flat fee ₹10/- per certificate,
                  whichever is higher
                </td>
              </tr>
              <tr>
                <td>Stamp Charges</td>
                <td>₹100/-</td>
              </tr>
              <tr>
                <td>Failed / Rejected Transactions</td>
                <td>₹0</td>
              </tr>
              <tr>
                <td>Periodic Statement</td>
                <td>
                  ₹0 charges by email.
                  <br />
                  ₹50 + courier charges at actuals for physical statements
                </td>
              </tr>
              <tr>
                <td>Delivery Instruction Book (5 leaves)</td>
                <td>₹150 + courier charges at actuals</td>
              </tr>
              <tr>
                <td>Modification in Client Master List (CML)</td>
                <td>₹50/-</td>
              </tr>
              <tr>
                <td>Transfer of shares from Fyers Demat to other Demat</td>
                <td>₹12.5+ GST per scrip</td>
              </tr>
              <tr>
                <td>Transfer of shares from other Demat to FYERS</td>
                <td>₹0</td>
              </tr>
              <tr>
                <td>Online DDPI Activation</td>
                <td>₹150 onetime</td>
              </tr>
            </table>
          </section>

          {/* section13 */}
          <section className={styles.explain}>
            <div className={styles.innerdiv}>
              <div>
                <h3>
                  Charges
                  <br />
                  <span> Explanation</span>
                </h3>
                <img src={rectexplain} alt="icon" />
              </div>

              <p>
                There are two types of charges are applied on trading or
                investing segments through Fyers
              </p>
              <ul className={styles.numlist}>
                <li>
                  <strong>Brokerage charges</strong> – Which goes to broker only
                  who provide you trading facility, like Brokerage charges only.
                </li>
                <li>
                  <strong>Government charges & Regulatory Charges</strong> –
                  These are multiple kind of charges who are applicable on each
                  transaction who goes to government body and regulatory
                  authorities.
                  <ul>
                    <li>
                      <strong>
                        STT/CTT (Securities/Commodities Transaction Tax):
                      </strong>
                      Imposed on transactions on exchanges. STT is applicable on
                      both buy and sell in equity delivery, and on the sell side
                      in intraday. CTT applies for commodities.
                    </li>
                    <li>
                      <strong>Exchange Transaction/Turnover Charges:</strong>{" "}
                      These are fees charged by exchanges like NSE, BSE, or MCX
                      based on transaction value.
                    </li>
                    <li>
                      <strong>GST (Goods and Services Tax):</strong> GST at 18%
                      is applicable on (brokerage + transaction charges + Demat
                      charges).
                    </li>
                    <li>
                      <strong>Stamp Duty Charges:</strong> State-specific
                      charges applicable on trading of securities and
                      commodities.
                    </li>
                    <li>
                      <strong>DP (Depository Participant) Charges:</strong> A
                      fee of ₹12.5 + 18% GST is charged by the depository for
                      selling equity stocks, applied per script sold.
                    </li>
                    <li>
                      <strong>SEBI Charges:</strong> A fee of ₹10 per crore is
                      levied by SEBI for regulating the market.
                    </li>
                    <li>
                      <strong>Call & Trade Charges:</strong> An additional fee
                      of ₹50 per order is applied for orders placed through
                      Fyers' call center or dealer, including auto square-off
                      orders.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </section>

          {/* section14 */}
          <section className={styles.tables}>
            <h3>
              Charges for <span>NRI Account Services</span> at FYERS
            </h3>
            <table className={styles.tablecus}>
              <tr>
                <th>Segment</th>
                <th>NRE</th>
                <th>NRO</th>
                <th>NRO Non-PIS</th>
              </tr>
              <tr>
                <td>Account Opening Charges </td>
                <td>FREE</td>
                <td>FREE</td>
                <td>FREE</td>
              </tr>
              <tr>
                <td>AMC</td>
                <td>FREE</td>
                <td>FREE</td>
                <td>FREE</td>
              </tr>
              <tr>
                <td>Equity Delivery</td>
                <td>0.5% or ₹50*</td>
                <td>0.5% or ₹50*</td>
                <td>0.5% or ₹50*</td>
              </tr>
              <tr>
                <td>F&O</td>
                <td>0.5% or ₹50*</td>
                <td>0.5% or ₹50*</td>
                <td>0.5% or ₹50*</td>
              </tr>
            </table>

            <h3>
              Fyers <span>Pros and Cons</span>
            </h3>
            <table className={styles.tablecus}>
              <tr>
                <th>Pros</th>
                <th>Cons</th>
              </tr>
              <tr>
                <td>Free online account opening.</td>
                <td>No offline branches.</td>
              </tr>
              <tr>
                <td>No brokerage on equity delivery trades.</td>
                <td>No 3-in-1 account.</td>
              </tr>
              <tr>
                <td>No hidden charges, cheaper than other brokers.</td>
                <td></td>
              </tr>
              <tr>
                <td>Smooth and robust trading platform.</td>
                <td></td>
              </tr>
            </table>

            <h3>
              Fyers <span>Registration</span> Details
            </h3>
            <table>
              <tr>
                <td>SEBI Registration No</td>
                <td>INZ000008524</td>
              </tr>
              <tr>
                <td>DP Registration No</td>
                <td>IN-DP-432-2019</td>
              </tr>
              <tr>
                <td>NRE TM Code</td>
                <td>90061</td>
              </tr>
              <tr>
                <td>NRO TM Code</td>
                <td>6697</td>
              </tr>
              <tr>
                <td>CDP L Member Code</td>
                <td>12089400</td>
              </tr>
            </table>
          </section>

          <section>
            <div className={styles.disclosure}>
              <div>
                <h3 className={styles.h3}>
                  <span>SEBI Risk</span> disclosures on derivatives -
                </h3>
                <ul>
                  <li>
                    9 out of 10 individual traders in the equity Futures and
                    Options segment incurred net losses.
                  </li>
                  <li>
                    On average, loss makers registered a net trading loss of
                    close to ₹50,000.
                  </li>
                  <li>
                    Over and above the net trading losses incurred, loss makers
                    expended an additional 28% of net trading losses as
                    transaction costs.
                  </li>
                  <li>
                    Those making net trading profits incurred between 15% to 50%
                    of such profits as transaction costs.
                  </li>
                </ul>
              </div>
              <div className={styles.rihimg}>
                <img src={disclosure} alt="icon" />
              </div>
            </div>
          </section>

          <a
            href={link}
            target="_blank"
            style={{ alignSelf: "center" }}
            className={styles.a}
          >
            <img src={trade} alt="icon" /> Open Your Demat Account
          </a>

          {/* section15 */}
          <section className={styles.lastdec}>
            <div className={styles.att}>
              <p>
                <img src={alertss} alt="icon" width={50} /> Attention: Don’t
                share login credentials or OTP to anyone even the Fyers team
                also.
                <img src={alertss} alt="icon" width={50} />
              </p>
            </div>
          </section>

          <section className={styles.mt}>
            <p>
              <img src={notes} alt="icon" width={50} />
              Notes: <br /> Rematerialization - In this process you can get your
              electronically held securities converted into physical
              certificates by processing your request via Fyers.
            </p>

            <div className={styles.disclosure}>
              <div>
                <ul>
                  <li>
                    <strong>Depository</strong> - In India, there are two
                    depositories that hold your stock accounts: CDSL (Central
                    Depository Services Limited) and NSDL (National Securities
                    Depository Limited). Fyers is part of the CDSL depositories.
                  </li>
                </ul>

                <div className={styles.att}>
                  <ul>
                    <li>
                      <strong>DDPI</strong> - SEBI introduced DDPI (Demat Debit
                      and Pledge Instruction) to mitigate risks associated with
                      Power of Attorney (PoA). DDPI allows investors to
                      authorize the sale or pledge of shares held in a Demat
                      account.
                    </li>
                    <li>
                      <strong>DDPI</strong> is optional, and clients can
                      continue to sell shares by entering the T-PIN for each
                      transaction.
                    </li>
                    <li>
                      <strong>DDPI</strong> can be used for selling stocks
                      without TPIN, pledging/unpledging shares, Margin Trade
                      Facility, debit of mutual fund units, and tendering shares
                      for buyback, takeover, or delisting.
                    </li>
                  </ul>
                </div>
              </div>

              <div className={styles.rihimg}>
                <img src={buy} alt="icon" />
              </div>
            </div>
          </section>
          <a
            href={link}
            target="_blank"
            style={{ alignSelf: "center" }}
            className={styles.a}
          >
            <img src={trade} alt="icon" /> Open Your Demat Account
          </a>
          <p className={styles.inv}>
            <img src={image8} alt="icon" width={50} /> Investments in the
            securities market are subject to market risks; read all the related
            documents carefully before investing.{" "}
            <img src={image8} alt="icon" width={50} />
          </p>
        </main>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Fyers;
